const cloneDeep = require("lodash.clonedeep");
export default {
  props: {
    record: {
      required: true
    }
  },
  data: () => ({ localRecord: null, saving: false, preventSaving: false }),
  mounted() {
    this.localRecord = window.localStorage.getItem("localRecord") ? JSON.parse(window.localStorage.getItem("localRecord")) : null;
    
    if (!this.localRecord || !this.record) {
      this.cloneRecord();
    }
    else if (this.record["PatientID"] != this.localRecord["PatientID"]) {
      this.cloneRecord();
    }
    else if (!this.record["lastModifyTimestamp"] || !this.localRecord["lastModifyTimestamp"])
    {
      this.cloneRecord();
    }
    else if (this.record["lastModifyTimestamp"] > this.localRecord["lastModifyTimestamp"]) {
      this.cloneRecord();
    }
  },
  methods: {
    cloneRecord() {
      this.localRecord = cloneDeep(this.record);
      if (this.localRecord && !this.localRecord.address)
        this.localRecord.address = {};
      if (this.localRecord && !this.localRecord.painMap)
        this.localRecord.painMap = {};
      if (this.localRecord && !this.localRecord.threshold)
        this.localRecord.threshold = 127;
      if (this.localRecord && !this.localRecord.contrast)
        this.localRecord.contrast = 80;
    },
    isFinalized() {
      if (!this.localRecord)
      {
        return false;
      }
      
      if ("Finalized" in this.localRecord)
      {
        return this.localRecord.Finalized;
      }
      else
      {
        return false;
      }
    },
    async finalize() {
      this.record.Finalized = true;
      this.localRecord.Finalized = true;
    },
    async quickSave() {
      //this.localRecord["lastModifyTimestamp"] = Date.now();
      this.$emit("save", this.localRecord);
      try {
        this.saving = true;
        await this.$store.dispatch("quickSaveRecord", this.localRecord);
        this.saving = false;
        window.localStorage.removeItem("localRecord");
        this.saveSuccess(1);
      } catch (error)
      {
        this.saving = false;
        this.saveFailed(error);
        console.error(error);
      }
    },
    async save() {
      //this.localRecord["lastModifyTimestamp"] = Date.now();
      this.$emit("save", this.localRecord);
      try {
        this.saving = true;
        await this.$store.dispatch("updateRecord", this.localRecord);
        this.saving = false;
        window.localStorage.removeItem("localRecord");
        console.log("saved");
				this.saveSuccess(4);
      } catch (error)
      {
        this.saving = false;
        this.saveFailed(error);
        console.error(error);
      }
    },
    async discard() {
      const cancel = await this.$confirm(this.$t("discard-warning"), {
        title: this.$t("warning"),
        buttonTrueText: this.$t("cancel"),
        buttonFalseText: this.$t("discard")
      });
    
      if (!cancel) {
        window.localStorage.removeItem("localRecord");
        this.$emit("discard");
        this.cloneRecord();
      }
    },
    async saveSuccess(tt) {
      const newDiv = document.createElement("div");
      newDiv.style = "display:block;position:fixed;z-index:1;top:0;left:0;width:100%;height:90%;overflow:auto;font-size:50px;background-color:rgba(255.0,255.0,0,1.0)";
      
      {
        const textDiv = document.createElement("div");
        textDiv.style = "text-align:center;";
        textDiv.style.margin = "auto";
        textDiv.style.width = "100%";
        textDiv.style.height = "50%";
//         textDiv.style.border = "3px solid green";
        
        newDiv.appendChild(textDiv);
        
//         const newContent = document.createTextNode("Hi there and greetings!");
//         textDiv.appendChild(newContent);
      }

      {
        const textDiv = document.createElement("div");
        textDiv.style = "text-align:center;";
        textDiv.style.margin = "auto";
        textDiv.style.width = "100%";
//         textDiv.style.border = "3px solid green";
        
        newDiv.appendChild(textDiv);
        
        const newContent = document.createTextNode(this.$t("save-success"));
        textDiv.appendChild(newContent);
      }
      
      document.getElementById("app").style.display = "none";
      
      document.body.insertBefore(newDiv, document.getElementById("app"));
      
      const delay = ms => new Promise(res => setTimeout(res, ms));
      
      await delay(tt*1000);
      
      document.body.removeChild(newDiv);
      document.getElementById("app").style.display = "";
    },
    async saveFailed() {
      const tryAgain = await this.$confirm(this.$t("save-failed"), {
        title: this.$t("error"),
        buttonTrueText: this.$t("save"),
        buttonFalseText: this.$t("cancel")
      });
      
      if (tryAgain)
      {
        this.save();
      }
    }
  },
  computed: {
    changed() {
      this.forceUpdate;
//       for (const key in this.localRecord) {
//         if (JSON.stringify(this.localRecord[key]) != JSON.stringify(this.record[key]))
//         {
//           console.log("Record changed:", key, JSON.stringify(this.localRecord[key]), JSON.stringify(this.record[key]));
//         }
//       }
      return JSON.stringify(this.record) !== JSON.stringify(this.localRecord);
    },
    validationErrors() {
      if (!this.record) return [];
      const errors = [];
      
			if (this.localRecord && !this.localRecord.gdpr_generated)
      {
        errors.push({ text: this.$t("gdpr-not-generated"), page: "Datasheet" });
      }
      
      if (this.record && !this.record.gdpr_generated)
			{
				errors.push({ text: this.$t("gdpr-not-generated"), page: "Datasheet" });
			}
			
      // datasheet
      if (!this.record.name)
        errors.push({ text: this.$t("name-is-empty"), page: "Datasheet" });
      if (!this.record.sex)
        errors.push({ text: this.$t("sex-is-empty"), page: "Datasheet" });
      if (
        !this.record.address ||
        !this.record.address.country ||
        !this.record.address.city ||
        !this.record.address.streetAddress ||
        !this.record.address.zipCode
      )
        errors.push({
          text: this.$t("address-is-not-complete"),
          page: "Datasheet"
        });
      if (!this.record.birthDate)
        errors.push({
          text: this.$t("birth-date-is-empty"),
          page: "Datasheet"
        });
      if (!this.record.height)
        errors.push({ text: this.$t("height-is-empty"), page: "Datasheet" });
      if (!this.record.weight)
        errors.push({ text: this.$t("weight-is-empty"), page: "Datasheet" });
      if (!this.record.phoneNumber)
        errors.push({
          text: this.$t("phone-number-is-empty"),
          page: "Datasheet"
        });
      if (!this.record.email)
        errors.push({ text: this.$t("email-is-empty"), page: "Datasheet" });
      if (!this.record.sendTo)
        errors.push({
          text: this.$t("send-to-field-is-empty"),
          page: "Datasheet"
        });

      // orders
      if (!this.record.orders || !this.record.orders.length)
        errors.push({
          text: this.$t("no-orders-have-been-placed"),
          page: "Orders"
        });
      // TODO more complex order validation

      // images
      if (!this.record.feetImage)
        errors.push({
          text: this.$t("no-feet-image-has-been-taken"),
          page: "Capture"
        });
			if (this.$store.state.user.heel1_lens_data && (!this.record.heel1Image && (!this.$store.state.user.heel1_lens_data.disabled)))
        errors.push({
          text: this.$t("no-heel1-image-has-been-taken"),
          page: "Capture"
        });
			if (this.$store.state.user.heel2_lens_data && (!this.record.heel2Image && (!this.$store.state.user.heel2_lens_data.disabled)))
        errors.push({
          text: this.$t("no-heel2-image-has-been-taken"),
          page: "Capture"
        });
      return errors;
    }
  },
  watch: {
    record: {
      deep: true,
      handler() {
        this.cloneRecord();
      }
    },
    localRecord : {
      deep: true,
      handler() {
        if (this.localRecord && this.record && JSON.stringify(this.record) !== JSON.stringify(this.localRecord)) {
          var recordCopy = cloneDeep(this.localRecord);
          recordCopy["lastModifyTimestamp"] = Date.now();
          window.localStorage.setItem("localRecord", JSON.stringify(recordCopy));
        }
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    let block = this.changed;
    
    if (block && this.preventSaving)
    {
      if (this.$store.state.user.type!='tech')
      {
        next(false);
        this.$emit("validateForm");
        return;
      }
      else
      {
        next();
      }
    }
    
    if (block) {
      block = !(await this.$confirm(this.$t("page-leave-warning"), {
        title: this.$t("warning"),
        buttonTrueText: this.$t("save"),
        buttonFalseText: this.$t("cancel")
      }));
    }
    
    if (!block) {
      if (this.changed) {
        await this.save();
      }
      next();
    } else {
      next(false);
    }
  }
};
